
<template>
  <div class='content' v-loading="loading" element-loading-background="rgba(255, 255, 255, 0)">
    <div class="title" v-if="isnotweixin">四环星网小助手
    </div>
    <div class="txt">
      <div class="box" id="chatContainer" style="height: 80%; overflow: auto;">
        <div v-for="(item, index) in txtList " :key="index">
          <div class="txt_1" v-if="item.role == 'user'">
            <span class="user_txt" style="">me</span>
            <pre class="perTxt">{{ item.content }}</pre>
          </div>
          <div class="txt_2" v-if="item.role == 'assistant'">
            <span>
              <img class="aiTxt_1" src="../style/img/logos.png" alt="">
            </span>
            <!-- <pre style="line-height: 20px;  white-space: pre-wrap;word-wrap: break-word;"> -->
            <vue-markdown class="markDiv" :html="false">{{ item.content }}</vue-markdown>
            <!-- </pre> -->
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer_1">
          <el-input :disabled="show" style="margin-right: 10px;" autosize placeholder="请输入内容" v-model="textarea">
          </el-input>
          <div class="footer_btn">
            <el-button type="primary" :disabled="show" icon="el-icon-position" @click="answer"></el-button>
          </div>
        </div>
        <p class="footer_p">仅供学习使用，请勿用于违法用途</p>
      </div>
    </div>
  </div>
</template>
<script>
import { answerI } from "@/api/system/chart";
import VueMarkdown from 'vue-markdown';
import 'github-markdown-css/github-markdown.css';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    'vue-markdown': VueMarkdown,
  },
  data () {
    return {
      isnotweixin: false,
      show: false,
      loading: false,
      textarea: '',
      txtList: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {

  },
  //方法集合
  methods: {
    isWeixinBrowser () {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    },

    answer () {
      console.log(this.textarea);
      if (this.textarea.trim() == '') {
        this.$message({
          message: '内容不能为空',
          type: 'warning'
        });
      } else {
        this.loading = true
        let item = {
          role: "user",
          content: this.textarea
        }
        this.txtList.push(item)
        var h4 = document.getElementById('chatContainer').scrollHeight
        setTimeout(() => {
          document.documentElement.scrollTop = parseInt(h4);
        }, 200);
        this.show = true
        answerI(this.txtList).then((res => {
          let respItem = {
            role: "assistant",
            // content: res
            content: res
          }
          this.txtList.push(respItem)
          var h4 = document.getElementById('chatContainer').scrollHeight
          setTimeout(() => {
            document.documentElement.scrollTop = parseInt(h4);
          }, 200);
          this.show = false
          this.userTxt = this.textarea
          this.textarea = ''
          this.aiTxt = res
          this.loading = false
        })).catch(() => {
          this.loading = false
          this.show = false
        })
      }
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // this.answer()

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    if (!this.isWeixinBrowser()) {
      this.isnotweixin = true;
    }
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () {
  }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.footer_p {
  margin: 0;
  margin-top: 2px;
  margin-bottom: 10px;
  color: #a5a5a6;
  font-size: 12px;
}

.markDiv /deep/ pre code {
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* 
pre code {
  white-space: pre-wrap;
  word-wrap: break-word;
} */

.footer_1 /deep/ .el-textarea__inner {
  max-height: 100px !important;
}

.footer_btn /deep/ .el-button {
  padding: 10px 20px !important;
}

.footer_btn {
  float: right;
  margin: auto auto;
}

.footer_1 {
  padding: 10px 10px 0px 10px;
  display: flex;
}

.footer {
  text-align: center;
  width: 100%;
  background-color: #f4f4f4;
  position: fixed;
  /* height: 60px; */
  bottom: 0px;
  box-shadow: 3px 0px 8px rgba(116, 113, 113, 0.3);
}

/* @import url(); 引入公共css类 */
.content {
  height: 100%;
  width: 100%;
  text-align: left;
  /* padding: 20px; */
}

.txt {
  margin-bottom: 100px;
  margin-top: 40px;
  /* padding: 0px 20px 20px 20px; */
  /* background: #748fac; */
}

.txt_1 {
  display: flex;
  justify-content: left;
  /* padding: 20px; */
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  /* padding-bottom: 10px; */
  /* margin-bottom: 10px; */
}

.txt_2 {
  display: flex;
  justify-content: left;
  padding: 20px;
  margin-top: 10px;
  background-color: #f7f7f8;
}

.aiTxt_1 {
  /* background-color: #1abc9c; */
  /* padding: 10px 13px 10px 13px; */
  /* color: #fff; */
  border-radius: 5px;
  margin-right: 10px;
  width: 38px;
  height: 42px;
}

.user_txt {
  background-color: #3ba2d5;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.title {
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  text-align: center;
}

.perTxt {
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
