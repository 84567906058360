import request from '@/utils/request'

export function answerI (d) {
  let data = {}
  data.conversations = d
  return request({
    url: '/answer',
    method: 'post',
    data: data
  })
}
