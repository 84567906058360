// main.js
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import request from "@/utils/request";
Vue.prototype.request = request

import 'highlight.js/styles/a11y-dark.css' // 导入代码高亮样式

// 判断配置文件是否要引入mock
process.env.VUE_APP_MOCK == "true" && require('../mock/index')

// 自定义一个代码高亮指令
// Vue.directive('highlight', function (el) {
//   const blocks = el.querySelectorAll('pre code')
//   blocks.forEach((block) => {
//     hl.highlightBlock(block)
//   })
// })
import VueHighlightJS from 'highlight.js';
Vue.use(VueHighlightJS)
Vue.directive('highlight', (el) => {
  let blocks = el.querySelectorAll('pre code')
  blocks.forEach((block) => {
    VueHighlightJS.highlightBlock(block)
  })
})

Vue.use(ElementUI);

new Vue({
  el: '#app',
  render: h => h(App)
});
